exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-conservation-sustainability-js": () => import("./../../../src/pages/conservation/sustainability.js" /* webpackChunkName: "component---src-pages-conservation-sustainability-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-enquiry-js": () => import("./../../../src/templates/enquiry.js" /* webpackChunkName: "component---src-templates-enquiry-js" */),
  "component---src-templates-experiences-js": () => import("./../../../src/templates/experiences.js" /* webpackChunkName: "component---src-templates-experiences-js" */),
  "component---src-templates-itineraries-js": () => import("./../../../src/templates/itineraries.js" /* webpackChunkName: "component---src-templates-itineraries-js" */),
  "component---src-templates-itineraryenquiry-js": () => import("./../../../src/templates/itineraryenquiry.js" /* webpackChunkName: "component---src-templates-itineraryenquiry-js" */),
  "component---src-templates-offers-js": () => import("./../../../src/templates/offers.js" /* webpackChunkName: "component---src-templates-offers-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-stay-js": () => import("./../../../src/templates/stay.js" /* webpackChunkName: "component---src-templates-stay-js" */),
  "component---src-templates-thankyou-js": () => import("./../../../src/templates/thankyou.js" /* webpackChunkName: "component---src-templates-thankyou-js" */)
}

